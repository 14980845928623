import { VUE_APP_AZURE_CLIENT_ID, VUE_APP_AZURE_SCOPE, VUE_APP_AZURE_TENANT_ID } from '@/config/env';
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';

const azureClientId = VUE_APP_AZURE_CLIENT_ID || '';
const tenantId = VUE_APP_AZURE_TENANT_ID || '';
const scope: string = VUE_APP_AZURE_SCOPE || '';

const loginScopes = [''];
const scopes = [scope];
const authority = `https://login.microsoftonline.com/${tenantId}`;

// Msal Configurations
const config = {
	auth: {
		authority,
		clientId: azureClientId,
	},
	cache: {
		cacheLocation: 'localStorage',
	},
};

const msalInstance = new PublicClientApplication(config);

export const msalLogin = async () => {
	await msalInstance.loginPopup({ scopes: loginScopes });
	const myAccounts = msalInstance.getAllAccounts();
	return myAccounts[0];
};

interface Request {
	scopes: string[];
	account?: AccountInfo;
}

export const msalGetToken = async (silent = false): Promise<AuthenticationResult | null> => {
	try {
		const request: Request = {
			scopes,
			account: msalInstance.getAllAccounts()[0],
		};
		const tokenResponse = await msalInstance.acquireTokenSilent(request);
		return tokenResponse;
	} catch (error) {
		if (silent) return null;
		console.error('Silent token acquisition failed. Using interactive mode');
		const tokenResponse = await msalInstance.acquireTokenPopup({ scopes });
		return tokenResponse;
	}
};

export const msalLogout = async () => {
	const account = msalInstance.getAllAccounts()[0];
	const response = await msalInstance.logoutPopup({ account });
	return response;
};
