
import { Organization } from '@/interfaces';
import { InputText } from '@/plugins/PrimeVue';
import { isEqual } from 'lodash';
import { defineComponent, Ref, ref, watch } from 'vue';
import CheckBox from "primevue/checkbox";
import { VUE_APP_API_URL } from "@/config/env";

export default defineComponent({
	components: {
		CheckBox,
		InputText,
	},
	props: {
		organization: {
			type: Object as () => Organization,
			required: true,
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
	},
	setup(props, { emit }) {
		const identicalAddress: Ref<Boolean> = ref(false);
		const InternalOrganization = ref({ ...props.organization });
		const apiUrl = VUE_APP_API_URL;

		if (isEqual(InternalOrganization.value.postal_address, InternalOrganization.value.billing_address)) {
			identicalAddress.value = true;
		}

		watch(InternalOrganization, () => {
			InternalOrganization.value = props.organization;
		});

		return {
			identicalAddress,
			InternalOrganization,
			apiUrl,
		};
	},
});
