
import { Organization } from '@/interfaces';
import { InputText } from '@/plugins/PrimeVue';
import { isEqual } from 'lodash';
import { defineComponent, Ref, ref, watch } from 'vue';
import { createAddress } from "@/interfaces/Address";
import { createMailbox } from "@/interfaces/Mailbox";
import CheckBox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";
import { VUE_APP_API_URL } from '@/config/env';

export default defineComponent({
	components: {
		InputText,
		CheckBox,
		FileUpload,
	},
	props: {
		organization: {
			type: Object as () => Organization,
			required: false,
			default: {},
		},
		errors: {
			type: Object as () => Record<string, string>,
			default: {},
		},
		isCreate: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, { emit }) {
		const identicalAddress: Ref<boolean> = ref(false);
		const InternalOrganization = ref({ ...props.organization });
		const apiUrl = VUE_APP_API_URL;
		const logoUrl = ref(InternalOrganization.value.logo || '');

		watch(
			InternalOrganization,
			() => {
				logoUrl.value = `${ apiUrl }/${ InternalOrganization.value.logo }`;
			},
			{ immediate: true }
		);

		const handleFile = async (event: any) => {
			InternalOrganization.value.logo = event.files[0];
			logoUrl.value = URL.createObjectURL(event.files[0]);
		}

		watch(InternalOrganization, () => {
			InternalOrganization.value = props.organization;
		});

		if(!InternalOrganization.value.id) {
			InternalOrganization.value.postal_address = createAddress();
			InternalOrganization.value.billing_address = createAddress();
			InternalOrganization.value.mailbox = createMailbox();
		}

		if (isEqual(InternalOrganization.value.postal_address, InternalOrganization.value.billing_address)) {
			identicalAddress.value = true;
		}

		watch(InternalOrganization.value, () => {
			emit('update:errors', InternalOrganization.value);

			if (identicalAddress.value && !isEqual(InternalOrganization.value.billing_address, InternalOrganization.value.postal_address)) {
				InternalOrganization.value.billing_address = { ...InternalOrganization.value.postal_address };
			}

			if (!isEqual(props.organization, InternalOrganization.value)) {
				emit('update:organization', InternalOrganization.value);
			}
		});

		return {
			handleFile,
			identicalAddress,
			InternalOrganization,
			logoUrl,
			apiUrl,
		};
	},
});
