<template>
	<div class="layout-footer">
		<div class="footer-logo-container">
			<img id="footer-logo" class="logo-image" src="/layout/images/logo-dark.svg" alt="diamond-layout" />
			<img id="footer-logo-text" class="logo-text" src="/layout/images/logo-text-dark.svg" alt="diamond-layout" />
		</div>
		<span class="copyright">&#169; {{ developerName }} - {{ currentYear }}</span>
	</div>
</template>

<script>
import { VUE_APP_DEVELOPER_NAME } from './config/env';

export default {
	name: "AppFooter",
	setup () {
		const currentDate = new Date();
		let currentYear = currentDate.getFullYear();
		const developerName = VUE_APP_DEVELOPER_NAME

		return {
			currentYear,
			developerName
		}
	}
};
</script>