import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import { supportedLocales } from '../config/supportedLocales';
import { VUE_APP_I18N_FALLBACK_LOCALE, VUE_APP_I18N_LOCALE } from '@/config/env';


export const i18n = createI18n({
	locale: VUE_APP_I18N_LOCALE || 'nl',
	fallbackLocale: VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: supportedLocales() as unknown as LocaleMessages<VueMessageType> | undefined
})
