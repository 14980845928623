// app vars
export const VUE_APP_API_URL = process.env.VUE_APP_API_URL || "https://test.squeezy.online";
export const VUE_APP_DEVELOPER_NAME = process.env.VUE_APP_DEVELOPER_NAME || "KRM";
export const VUE_APP_APP_NAME = process.env.VUE_APP_APP_NAME || "SQUEEZY-TEST";
export const NODE_ENV = process.env.NODE_ENV || "development" || "production";
export const VUE_APP_AUTH_ENABLED = process.env.VUE_APP_AUTH_ENABLED || "" || true;

// i18n translation vars
export const VUE_APP_I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE || '__VUE_APP_I18N_LOCALE__' || 'nl';
export const VUE_APP_I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || '__VUE_APP_I18N_FALLBACK_LOCALE__' || 'en';

// old azure vars
export const VUE_APP_AZURE_CLIENT_ID = process.env.VUE_APP_AZURE_CLIENT_ID || '__VUE_APP_AZURE_CLIENT_ID__';
export const VUE_APP_AZURE_TENANT_ID = process.env.VUE_APP_AZURE_TENANT_ID || '__VUE_APP_AZURE_TENANT_ID__';
export const VUE_APP_AZURE_SCOPE = process.env.VUE_APP_AZURE_SCOPE || '__VUE_APP_AZURE_SCOPE__';