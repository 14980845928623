import { VUE_APP_API_URL } from '@/config/env';
import axios, { AxiosInstance } from 'axios';

const appApiService = axios.create({ baseURL: `${VUE_APP_API_URL || "/"}` });

export {
	axios,
	appApiService,
	AxiosInstance as ApiInstance,
};
