import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/pages/Login.vue';
import ClientList from '@/views/Client/List/ClientList.vue';
import InvoiceList from '@/views/Invoice/List/InvoiceList.vue';
import InvoiceTemplateList from "@/views/InvoiceTemplate/List/InvoiceTemplateList.vue";
import OrganizationList from '@/views/Organization/List/OrganizationList.vue';
import InvoiceEditor from '@/views/Invoice/Editor/InvoiceEditor.vue';
import InvoiceTemplateEditor from '@/views/InvoiceTemplate/Editor/InvoiceTemplateEditor.vue';
import InvoicePreviewer from '@/views/Invoice/Previewer/InvoicePreviewer.vue';
import InvoicePdfPreviewer from '@/views/Invoice/PdfPreviewer/InvoicePdfPreviewer.vue';
import InvoiceTemplateDetailPage from '@/views/InvoiceTemplate/Detail/InvoiceTemplateDetailPage.vue';
import ClientEditor from '@/views/Client/Editor/ClientEditor.vue';
import OrganizationEditor from '@/views/Organization/Editor/OrganizationEditor.vue';
import { getAccessToken } from '@/utils/accessToken';
import ClientDetailPage from "@/views/Client/Detail/ClientDetailPage.vue";
import OrganizationDetailPage from "@/views/Organization/Detail/OrganizationDetailPage.vue";
import { NODE_ENV, VUE_APP_APP_NAME, VUE_APP_AUTH_ENABLED } from '@/config/env';

const routes = [
	{
		path: '/',
		redirect: 'invoices',
	},
	{
		path: '/login',
		name: 'login',
		exact: true,
		component: Login,
		meta: {
			authenticationRequired: true,
			title: 'Login',
			breadcrumb: [{ parent: 'Account', label: 'Login' }],
		},
	},
	{
		path: '/invoices',
		name: 'invoices',
		exact: true,
		component: InvoiceList,
		meta: {
			title: 'Facturen',
			breadcrumb: [{ label: 'Facturen' }],
		},
	},
	{
		path: '/invoices/create',
		name: 'invoice-create',
		component: InvoiceEditor,
		meta: {
			title: 'Factuur aanmaken',
			breadcrumb: [{ label: 'Factuur aanmaken' }],
		},
	},
	{
		path: '/invoices/:id/edit',
		name: 'invoice-edit',
		component: InvoiceEditor,
		meta: {
			title: 'Factuur updaten',
			breadcrumb: [{ label: 'Factuur aanpassen' }]
		},
	},
	{
		path: '/invoices/:id/preview',
		name: 'invoice-preview',
		component: InvoicePreviewer,
		meta: {
			title: 'Factuur previewen',
			breadcrumb: [{ label: 'Factuur preview' }]
		},
	},
	{
		path: '/invoices/:id/preview/:reminderIndex',
		name: 'invoice-pdf-preview',
		component: InvoicePdfPreviewer,
		meta: {
			title: 'Factuur previewen voor pdf',
			breadcrumb: [{ label: 'Factuur preview' }]
		},
	},
	{
		path: '/organization/:organizationId/invoices/:id/preview/:reminderIndex',
		name: 'invoice-pdf-preview',
		component: InvoicePdfPreviewer,
		meta: {
			title: 'Factuur previewen voor pdf',
			breadcrumb: [{ label: 'Factuur preview' }]
		},
	},
	{
		path: '/invoice-templates',
		name: 'invoice-templates',
		exact: true,
		component: InvoiceTemplateList,
		meta: {
			title: 'Factuur template',
			breadcrumb: [{ label: 'Sjablonen' }],
		},
	},
	{
		path: '/invoice-templates/create',
		name: 'invoice-template-create',
		component: InvoiceTemplateEditor,
		meta: {
			title: 'Factuursjabloon aanmaken',
			breadcrumb: [{ label: 'Sjabloon aanmaken' }],
		},
	},
	{
		path: '/invoice-templates/:id/edit',
		name: 'invoice-template-edit',
		component: InvoiceTemplateEditor,
		meta: {
			title: 'Factuur template updaten',
			breadcrumb: [{ label: 'Sjabloon aanpassen' }],
		},
	},
	{
		path: '/invoice-templates/:id',
		name: 'invoice-template-detail',
		component: InvoiceTemplateDetailPage,
		meta: {
			title: 'Sjabloon bekijken',
			breadcrumb: [{ label: 'Sjabloon details' }],
		},
	},
	{
		path: '/clients',
		name: 'clients',
		exact: true,
		component: ClientList,
		meta: {
			title: 'Klanten',
			breadcrumb: [{ label: 'Klanten' }],
		},
	},
	{
		path: '/clients/create',
		name: 'client-create',
		component: ClientEditor,
		meta: {
			title: 'Klant toevoegen',
			breadcrumb: [{ label: 'Klant aanmaken' }],
		},
	},
	{
		path: '/clients/:id/edit',
		name: 'client-edit',
		component: ClientEditor,
		meta: {
			title: 'Klantgegevens aanpassen',
			breadcrumb: [{ label: 'Klant aanpassen' }],
		},
	},
	{
		path: '/clients/:id',
		name: 'client-view',
		component: ClientDetailPage,
		meta: {
			title: 'Klant toevoegen',
			breadcrumb: [{ label: 'Klant details' }],
		},
	},
	{
		path: '/organizations',
		name: 'organizations',
		exact: true,
		component: OrganizationList,
		meta: {
			title: 'Organisaties',
			breadcrumb: [{ label: 'Organisaties' }],
		},
	},
	{
		path: '/organizations/create',
		name: 'organization-create',
		component: OrganizationEditor,
		meta: {
			title: 'Organisatie aanmaken',
			breadcrumb: [{ label: 'Organisatie aanmaken' }],
		},
	},
	{
		path: '/organizations/:id/edit',
		name: 'organization-edit',
		component: OrganizationEditor,
		meta: {
			title: 'Organisatie updaten',
			breadcrumb: [{ label: 'Organisatie aanpassen' }],
		},
	},
	{
		path: '/organizations/:id',
		name: 'organization-view',
		component: OrganizationDetailPage,
		meta: {
			title: 'Organisatie toevoegen',
			breadcrumb: [{ label: 'Organisatie details' }],
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
});

const isDev = NODE_ENV === 'production' ? '' : 'DEV '
const appName = VUE_APP_APP_NAME

const isAuthEnabled = VUE_APP_AUTH_ENABLED == 'false' ? false : true;
if (isAuthEnabled === true) {
	router.beforeEach(async (to, from, next) => {
		if (!to.matched.some(record => record.meta.authenticationRequired)) {
			const accessToken = getAccessToken();
			if (!accessToken) {
				next({ name: 'login' });
				return;
			}
		}
		document.title = `${isDev}${appName} | ${to.meta.title}`
		next();
	});
} else {
	router.beforeEach(async (to, from, next) => {
		console.error("======= AUTH IS DISABLED =======")
		document.title = `${isDev}${appName} | ${to.meta.title}`
		next();
	});
}

export default router;
